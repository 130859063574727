<template>
  <div>
    <hero-bar :has-right-visible="false">
      {{ $getTranslation("general.views.measurement", 1, true) }}
    </hero-bar>

    <section class="section is-main-section capitalize">
      <b-button
        v-if="ability.can('create', 'scale')"
        tag="router-link"
        :to="{ name: 'measurement.create' }"
        type="is-primary"
        icon-left="plus"
        >{{ $getTranslation("form.general.add") }}
        {{ $getTranslation("general.views.measurement", 0) }}</b-button
      >
    </section>

    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        icon="account-multiple"
      >
        <div v-if="!loading && error === null">
          <app-table
            :columns="columns"
            :page="page"
            :total="total"
            :editRoute="editRoute"
            :deleteRoute="endpoint"
            @onPageChange="onPageChange"
            :pagination-top="true"
            :pagination-bottom="true"
          />
        </div>
        <app-section-empty v-else :is-loading="loading"></app-section-empty>
      </card-component>
    </section>
  </div>
</template>

<script>
import { ApiScales } from "@/api-routes.js"
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import Table from '@/components/elements/table/Table'

export default {
  name: 'measurement',
  mixins: [ListTemplateMixin],
  components: {
    'app-table': Table
  },

  data () {
    return {
      endpoint: ApiScales + '/measurement',
      editRoute: 'measurement.edit',
      total: 0,
      page: 1,
      // perPage: 15,
      // Table
      columns: {'title':'form', 'category':'form', 'status':'form', 'created':'general', 'updated':'general', 'deleted':'general'}
    }
  },

  created () {
    this.fetchPaginatedItems ()
    console.log(this.ability.can('list', 'scale'))
    // console.log('Measurement Scales created ', this.$route.meta.routeName)
  }
}
</script>
